import React from 'react';
import css from '../css/Body.module.css';
import Home from './body/Home';
import Category from './body/Category';
import SubCategory from './body/SubCategory';
import Recipe from './body/Recipe';

export default ({ state }) => {	
	const content = () => {
		if (state.params.recipeId) return <Recipe state={state} />;
		if (state.params.subCategoryId) return <SubCategory state={state} />;
		if (state.params.categoryId) return <Category state={state} />;
		return <Home state={state} />
	};
	
	return (
		<div className={css.main}>{ content() }</div>
	);
}