import axios from 'axios';
import env from './env';

const requester = axios.create({ baseURL: env.domain + 'api/' });

const Get = (params, callback) => { httpRequest('get', params, callback); }
const Post = (params, callback) => { httpRequest('post', params, callback); }

const httpRequest = (method, params, callback) => {
	(async () => {
		const resp = await requester[method]('', { params: params });
		if (typeof(callback) === 'function') callback(resp.data);
	})();
}

export default Get;
export { Get, Post };