import React from 'react';
import Categories from './Categories';
import css from '../css/Navigation.module.css';

export default ({ state }) => {
	if (state.categories.length === 0) return '';
	
	const subCategory = () => {
		if (!state.params.categoryName || !state.categoryByName[state.params.categoryName] || !state.categoryByName[state.params.categoryName].categories || state.categoryByName[state.params.categoryName].categories.length === 0) return '';
		return <Categories key={state.categoryByName[state.params.categoryName].id} state={state} categories={state.categoryByName[state.params.categoryName].categories} level="sub"/> 
	};
console.log(state);
	
	const divider = (state.params.categoryName) ? <div className={css.divider}></div> : '';
	return (
		<div className={css.navigation}>
			<Categories state={state} categories={state.categories} level="top" />
			{ divider }
			{ subCategory() }
		</div>
	);
}