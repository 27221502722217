import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import globalCSS from '../css/global.module.css';
import css from '../css/Categories.module.css';

export default ({ state, categories, level }) => {
	const [ view, setView ] = useState({hovering: true, editing: 0});
	
	return (
		<div className={['clearfix', css.main].join(' ')}>
			{ (level === 'top') ? <Link className={css.homeLink} to="/"><svg><rect fill="#5555ff" x="5" y="6" width="12" height="9"></rect><polygon fill="#5555ff" points="11,1 22,9 1,9"></polygon><circle fill="#cccccc" cx="11" cy="10" r="2"></circle><rect fill="#cccccc" x="9" y="11" width="4" height="4"></rect></svg></Link> : '' }
			{
				categories.map((category, idx) => {
					let categoryCSS = ((level === 'top' && state.categoryByName[state.params.categoryName] && state.categoryByName[state.params.categoryName].id === category.id) || (level === 'sub' && state.categoryByName[state.params.subCategoryFullName] && state.categoryByName[state.params.subCategoryFullName].id === category.id)) ? [globalCSS.link, css.category, css.selected] : [globalCSS.link, css.category];
					if (level === 'sub') categoryCSS.push(css.sub);
					
					return getLink({ state, view, category, categoryCSS, level, idx });
				})
			}
		</div>
	);
}

const getLink = (props) => {
	let escaped = (text) => { return (typeof(text) === 'string') ? text.replace(/ /g, '+') : text; };
	
	if (!props.state.admin || !props.view.hovering || props.view.editing < 0) return <Link key={props.category.id} id={'category-'+props.category.id} className={props.categoryCSS.join(' ')} to={ escaped(((props.level === 'sub') ? '/' + props.state.params.categoryName : '') + '/' + props.category.name) }>{props.category.name}</Link>;

	return <div key={props.category.id}>
		<Link id={'category-'+props.category.id} className={props.categoryCSS.join(' ')} to={ escaped(((props.level === 'sub') ? '/' + props.state.params.categoryName : '') + '/' + props.category.name) }>{props.category.name}</Link>
	</div>;
	/*
	let classes;
	if (view.showSaveAndDelete) classes = ''
	
	<div className={css.edit}></div> : '';
	*/
}