import history from './history';

export default (state) => {
	const urlPath = history.location.pathname;
	const urlParts = urlPath.split(/\//);
	
	let params = {
		categoryName: urlParts[1],
		subCategoryName: urlParts[2],
		recipeName: urlParts[3]
	};
	
	let dashToSpace = (text) => { return (typeof(text) === 'string') ? text.replace(/\+/g, ' ') : text; }

	if (state && state.categories){
		for (let key in {categoryName: true, subCategoryName: true, recipeName: true}) params[key] = dashToSpace(params[key]);
		
		params.subCategoryFullName = (params.subCategoryName) ? params.categoryName + ' | ' + params.subCategoryName : undefined;
		params.recipeFullName = (params.recipeName) ? params.categoryName + ' | ' + params.subCategoryName + ' | ' + params.recipeName : undefined;
		
		params.categoryId = (state.categoryByName[params.categoryName]) ? state.categoryByName[params.categoryName].id : null;
		params.subCategoryId = (state.categoryByName[params.subCategoryFullName]) ? state.categoryByName[params.subCategoryFullName].id : null;
		params.recipeId = (state.recipeByName[params.recipeFullName]) ? state.recipeByName[params.recipeFullName].id : null;
	}
	
	state.params = params;
	state.params.urlPath = urlPath;
	state.setSelectedFromParams(params);
	
	return params;
}