import React, { useState, useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import history from '../history';
import urlParams from '../urlParams';
import css from '../css/App.module.css';
import apiGet from '../API';
import initState from '../State';
import Admin from './Admin';
import Main from './Main';

export default () => {
	const [ categories, setCategories ] = useState([]);
	const state = initState({ categories });
	
	useEffect(() => { apiGet({ action: 'getData' }, setCategories); }, []);
	
	return (
		<div className={css.main}>
			<Router history={history}>
				<div>
					<Route path="/admin" render={(props) => {
						urlParams(state);
						return <Admin state={state} /> 
					}} />
					<Route path="/:categoryName?/:subCategoryName?/:recipeId?" render={(props) => { 
						if (props.location.pathname.toLowerCase().indexOf('/admin') === 0) return '';
						urlParams(state);
						return <Main state={state} />
					}} />
				</div>
			</Router>
		</div>
	);
};
