import env from './env';
import urlParams from './urlParams';

export default ({ categories, updated }) => {	
	function State(categories, updated){
		if (!Array.isArray(categories)) categories = [];
		this.env = env;
		this.categories = categories;
		this.categoryById = {};
		this.categoryByName = {};
		this.recipeById = {};
		this.recipeByName = {};
		this.selected = { categoryId: null, subCategoryId: null, recipeId: null };
		
		this.setSelectedFromParams = () => {
			this.selected.categoryId = this.params.categoryId || null
			this.selected.subCategoryId = this.params.subCategoryId || null;
			this.selected.recipeId = this.params.recipeId || null;
		};
		
		const mapShortcuts = (category, fullName) => {
			fullName = (!fullName) ? category.name : fullName + ' | ' + category.name;
			
			this.categoryById[category.id] = category;
			this.categoryByName[fullName] = category;
			
			if (category.recipies) category.recipies.map((recipe) => {
				this.recipeById[recipe.id] = recipe;
				this.recipeByName[fullName + ' | ' + recipe.name] = recipe;
				return true;
			});
			if (category.categories) category.categories.map((subCategory) => { return mapShortcuts(subCategory, fullName); });
		};
		categories.map((category) => { return mapShortcuts(category, ''); });
		
		urlParams(this);

		return this;
	};
	
	return new State(categories, updated);
};