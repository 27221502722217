import React from 'react';
import css from '../../css/SubCategory.module.css';
import { Highlight } from './Recipe';

export default ({ state }) => {
	const subCategory = state.categoryById[state.selected.subCategoryId];

	const recipies = () => {
		return subCategory.recipies.map((recipe) => {
			return <Highlight key={recipe.id} state={state} options={{ recipeId: recipe.id }} />
		});
	}
	
	return (
		<div className={css.main}>{ recipies() }</div>
	);
}