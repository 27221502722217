import React from 'react';
import { Link } from 'react-router-dom';
import globalCss from '../css/global.module.css';
import css from '../css/Header.module.css';
import { AdminLink } from './Admin';

export default ({ state }) => {
	const admin = (state.admin) ? <AdminLink state={state}/> : '';
	return (
		<div className={css.main}>
			<Link className={[globalCss.link, css.header].join(' ')} to="/">HomeCookBook.com</Link>
			<img className={[css.image, globalCss.popImage].join(' ')} src="/image/header-food.jpg" alt="Header Food"/>
			{admin}
		</div>
	);
}