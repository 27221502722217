import React from 'react';
import { Link } from 'react-router-dom';
import globalCSS from '../css/global.module.css';
import css from '../css/Footer.module.css';

export default ({ state }) => {
	const year = new Date().getFullYear();
	
	return (
		<div className={css.footer}>
			<Link className={[globalCSS.link, css.link].join(' ')} to="/">Home</Link>
			<div className={[globalCSS.link, css.link].join(' ')}>Contact Us</div>
			<div className={css.copyright}>Copyright @ 2011 - {year} David Hicken.  All Rights Reserved.</div>
		</div>
	);
}
