import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import history from '../history';
import css from '../css/Admin.module.css';
import apiGet from '../API';
import Header from './Header';

const AdminPage = ({ state }) => {
	let content;
	if (state.params.urlPath === '/admin/logout') content = Logout(state);
	else if (state.admin) content = Loggedin(state);
	else content = LoginPage(state);
	
	return (
		<div className={css.main}>
			<Header state={state} />
			<div className={css.heading}>Admin Login</div>
			{content}
		</div>
	);
}

const AdminLink = ({ state }) => {	
	return (
		<Link className={css.link} to="/admin"><img className={css.linkImg} src="/image/admin.png" alt="Admin"/></Link>
	);
}

const Loggedin = (state) => {
	return (
		<div className={css.loggedin}>
			<div>Currently Logged In</div>
			<div><button onClick={() => { Logout(state); }}>Logout</button></div>
		</div>
	);
}

const LoginPage = (state) => {
	const [ login, setLogin ] = useState({ username: '', password: '' });
	
	return (
		<div>
			<div className={css.loginPage}>
				<div>
					<div>Username: </div>
					<div><input type="text" value={login.username} onChange={(e) => { setLogin({ ...login, username: e.target.value }); }} onKeyDown={(e) => { if (e.keyCode === 13) Login(state, login); }}/></div>
				</div>
				<div>
					<div>Password: </div>
					<div><input type="password" value={login.password} onChange={(e) => { setLogin({ ...login, password: e.target.value }); }} onKeyDown={(e) => { if (e.keyCode === 13) Login(state, login); }}/></div>
				</div>
			</div>
			<div className={css.submit}><button onClick={() => { Login(state, login); }}>Login</button></div>
		</div>
	);
}

const Login = (state, login) => {
	apiGet({ action: 'login', username: login.username.toLowerCase(), password: login.password }, (data) => {
		if (data.session) state.admin = data.session;
		history.push('/');
	});
	delete state.login;
	return ( <div>Logging In ... Please Wait</div> );
}

const Logout = (state) => {
	apiGet({ action: 'logout', session: state.admin }, () => {
		delete state.admin;
		history.push('/');
	});
	return ( <div>Logging Out ... Please Wait</div> );
}

export default AdminPage;
export { AdminPage, AdminLink };
