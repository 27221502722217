import React from 'react';
import { Link } from 'react-router-dom';
import globalCSS from '../../css/global.module.css';
import css from '../../css/Recipe.module.css';
import { uid } from '../../util';

export default ({ state }) => {
	const recipe = state.recipeById[state.selected.recipeId];
	let stepNumber = 1;

	const time = { 
		prep: (parseInt(recipe.prep) > 0) ? <div className={css.time}><div>Prep:</div><div>{formatMultiTime(recipe.prep)}</div></div> : '',
		cook: (parseInt(recipe.cook) > 0) ? <div className={css.time}><div>Cook:</div><div>{formatMultiTime(recipe.cook)}</div></div> : '',
		chill: (parseInt(recipe.chill) > 0) ? <div className={css.time}><div>Rest:</div><div>{formatMultiTime(recipe.chill)}</div></div> : '',
		total: calcTotalTime(recipe),
	};

	const defineStep = (step) => {
		return <div key={uid()} className={css.step}>
			<div className={css.stepNumber}><div>{stepNumber++}</div></div>
			<div className={css.stepContent}>
				<div className={[css.stepName, css.title].join(' ')}>{step.name}</div>
				<div className={css.stepDetails}>
					{ ingredients(step) }
					<div className={(step.ingredients.length === 0 ) ? [css.stepInfo, css.instructionsWide].join(' ') : css.stepInfo}>
						<div className={css.head}>Instructions</div>
						<div className={css.stepInstruction}>
							<div dangerouslySetInnerHTML={{__html: step.instruction.replace(/\n/g, '<br/>')}}></div>
							<div>{stepImage(step)}</div>
						</div>
					</div>
				</div>
				{stepHint(step)}
			</div>
		</div>;
	};
	
	const ingredients = (step) => {
		return (step.ingredients.length === 0) 
		? '' 
		: <div className={css.ingredients}>
			<div className={css.head}>Ingredients</div>
			{ step.ingredients.map(defineIngredient) }
		</div>;
	};
	
	const defineIngredient = (ingredient) => {
		return <div key={uid()} className={css.ingredient}>
			<div className={css.ingredientQty}>{ingredient.qty}</div>
			<div className={css.ingredientName}>{ingredient.name}</div>
		</div>;
	};
	
	const stepImage = (step) => { return (step.image.src) ? <img className={globalCSS.popImage} src={state.env.domain + step.image.src} alt={step.name}/> : ''; };
	const stepHint = (step) => { return (step.hint) ? <div className={css.stepHint}>{step.hint}</div> : ''; };
	const recipeNote = (note) => { return (note) ? <div className={css.recipeNote}>{note}</div> : ''; };

	return (
		<div className={css.main}>
			<div className={css.preable}>
				<div className={css.summary}>
					<div className={css.heading}>{recipe.name}</div>
					<div className={css.facts}>
						<div className={css.times}>
							<div className={css.title}>
								<div>Total Time:</div>
								<div>{time.total}</div>
							</div>
							{time.prep}
							{time.cook}
							{time.chill}
						</div>
						<div className={css.skill}>
							<div className={css.title}>Skill Level</div>
							<div>{recipe.skill}</div>
						</div>
					</div>
					<div className={css.intro} dangerouslySetInnerHTML={{__html: recipe.intro.replace(/\n/g, '<br/>')}}></div>
				</div>
				<div className={css.primaryImage}>{ (recipe.images.length > 0) ? <img className={globalCSS.popImage} src={state.env.domain + recipe.images[0].src} alt={recipe.name} /> : '' }</div>
			</div>
			<div className={css.steps}>
				{recipe.steps.map(defineStep)}
			</div>
			{recipeNote(recipe.note)}
		</div>
	);
};




export const Feature = ({ state, options }) => {
	const recipeIds = Object.keys(state.recipeById);
	if (recipeIds.length === 0)  return '';
	if (typeof(options) !== 'object') options = {};
	
	const recipe = findRecipe(state, options);
	if (!recipe) return '';
	
	if (!options.link) options.link = findRecipeLink(state, recipe.id);
	
	let ingredients = [].concat(...recipe.steps.map((step) => { return step.ingredients.map((ingredient) => { return { name: ingredient.name, qty: ingredient.qty } }) }));
	if (ingredients.length > 10){
		ingredients.splice(10, ingredients.length, { name: '...', qty: '...' });
		ingredients.splice(5, 0, { name: '...', qty: '...' });
	}
	ingredients = [ingredients.slice(0, Math.ceil(ingredients.length / 2)), ingredients.slice(Math.ceil(ingredients.length / 2))];
	
	
	return (
		<div className={css.feature}>
			<div className={css.header}>Featured Recipe</div>
			<div className={css.title}><Link to={options.link}>{recipe.name}</Link></div>
			<div className={css.content}>
				<table> { ingredients[0].map((ingredient) => { return <tr key={ingredient.name + ingredient.qty} className={css.ingredient}><td className={css.ingredientName}>{ingredient.name}</td><td className={css.ingredientQty}>{ingredient.qty}</td></tr> }) } </table>
				<table> { ingredients[1].map((ingredient) => { return <tr key={ingredient.name + ingredient.qty} className={css.ingredient}><td className={css.ingredientName}>{ingredient.name}</td><td className={css.ingredientQty}>{ingredient.qty}</td></tr> }) } </table>
				{ (recipe.images.length > 0) ? <Link to={options.link}><img className={globalCSS.popImage} src={state.env.domain + recipe.images[0].src} alt={recipe.name} /></Link> : '' }
			</div>
		</div>
	);
};

export const Highlight = ({ state, options }) => {
	const recipeIds = Object.keys(state.recipeById);
	if (recipeIds.length === 0)  return '';
	if (typeof(options) !== 'object') options = {};
	
	const recipe = findRecipe(state, options);
	if (!recipe) return '';
	
	if (!options.link) options.link = findRecipeLink(state, recipe.id);
	
	return (
		<div className={css.highlight}>
			<div className={css.title}>
				<Link to={options.link} className={globalCSS.link}>{(options.name) ? options.name : recipe.name}</Link>
			</div>
			<div className={[globalCSS.popImage, css.thumbnail].join(' ')}>
				<Link to={options.link}>
					{ (recipe.images.length > 0) ? <img src={state.env.domain + recipe.images[0].src} alt={options.name} /> : '' }
					{ (recipe.images.length > 0 && options.name) ? <img src={state.env.domain + 'image/folder.png'} className={css.categoryOverlay} alt={options.name} /> : '' }
				</Link>
			</div>
		</div>
	);
};




const formatTime = (minutes) => {
	const minutesLeft = minutes % 60;
	return parseInt(minutes / 60) + ':' + ((minutesLeft < 10) ? '0' + minutesLeft : minutesLeft);
};

const formatMultiTime = (time) => {
	if (typeof(time) === 'string' && time.indexOf('-') > -1){
		const timeParts = time.split('-');
		return formatTime(parseInt(timeParts[0])) + ' - ' + formatTime(parseInt(timeParts[1]));
	}
	
	return formatTime(parseInt(time));
};

const calcTotalTime = (recipe) => {
	let totalTime = 0;

	for (let key in {prep: true, cook: true, chill: true}){
		if (typeof(recipe[key]) === 'string' && recipe[key].indexOf('-') > -1) totalTime += parseInt(recipe[key].split('-')[1]);
		else totalTime += parseInt(recipe[key]);
	}
	
	return formatTime(totalTime);
};


const findRecipe = (state, options) => {
	let recipeIds = [];
	
	if (!options.categoryIds && !options.categoryId && !options.recipeIds && !options.recipeId) recipeIds = [...Object.keys(state.recipeById)];
	else {
		if (options.categoryIds) recipeIds = [...recipeIds, ...[].concat(...options.categoryIds.map((categoryId) => { return drillCategories(state, { categoryId }) }))];
		if (options.categoryId) recipeIds = [...recipeIds, ...drillCategories(state, options)];
		if (options.recipeIds) recipeIds.push(...options.recipeIds);
		if (options.recipeId) recipeIds.push(options.recipeId);
	}
	
	if (recipeIds.length === 0) return;
	
	const recipeId = recipeIds[parseInt(Math.random() * recipeIds.length)];
	
	return state.recipeById[recipeId];
};

const drillCategories = (state, options) => {
	let recipeIds = [];
	
	for (let subCategoryId in state.categoryById){
		if (state.categoryById[subCategoryId].parentId === options.categoryId) recipeIds = [...recipeIds, ...drillCategories(state, { categoryId: subCategoryId })];
	}
	
	if (state.categoryById[options.categoryId].recipies) recipeIds = [...recipeIds, ...state.categoryById[options.categoryId].recipies.map((recipe) => { return recipe.id; })];
	
	return recipeIds;
};

const findRecipeLink = (state, recipeId) => {
	const recipe = state.recipeById[recipeId];
	const subCategory = state.categoryById[recipe.categoryId];
	const category = state.categoryById[subCategory.parentId];
	return '/' + category.name + '/' + subCategory.name + '/' + recipe.name;	
};