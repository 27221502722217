import React from 'react';
import css from '../../css/Category.module.css';
import { Highlight } from './Recipe';

export default ({ state }) => {
	const category = state.categoryById[state.selected.categoryId];

	const subCategories = () => {
		return category.categories.map((subCategory) => {
			return <Highlight key={subCategory.id} state={state} options={{ categoryId: subCategory.id, name: subCategory.name, link: '/' + category.name + '/' + subCategory.name }} />
		});
	}
	
	return (
		<div className={css.main}>{ subCategories() }</div>
	);
}