import React from 'react';
import css from '../css/Main.module.css';
import Header from './Header';
import Navigation from './Navigation';
import Body from './Body';
import Footer from './Footer';

export default ({ state }) => {	
	return (
		<div className={css.main}>
			<Header state={state} />
			<Navigation state={state}/>
			<Body state={state}/>
			<Footer state={state} />
		</div>
	);
}