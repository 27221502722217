import React from 'react';
import css from '../../css/Home.module.css';
import { Feature } from './Recipe';

export default ({ state }) => {
	return (
		<div className={css.main}>
			<h2>Welcome Home!</h2>
			<p className={css.intro}>This is the home of my family's favorite recipes.</p>
			<div className={css.list}>
				<div>In this home we do ...</div>
				<div>
					<div>
						<ul>
							<li>Simplifying</li>
							<li>Crazy week nights</li>
							<li>Do-over</li>
							<li>Real</li>
							<li>Sunday feasts</li>
							<li>Daily dinner</li>
						</ul>
					</div>
					<div>
						<ul>
							<li>Tradition</li>
							<li>Make Ahead</li>
							<li>Healthy-ish</li>
							<li>Budget Friendly</li>
							<li>Weekend Breakfast</li>
						</ul>
					</div>
				</div>
				<div>... Family Meal Time</div>
			</div>
			<p>As a mom of 6, I get it. Sometimes cooking is a joy, but frequently it is a chore. By the end of the afternoon all you want is a nap but everyone else wants food. If you were organized before the weekly shopping trip you might have a meal plan and all the ingredients, but most likely it's time to play pantry scramble.</p>
			<p>I believe in simplifying my life, including in the kitchen. Not every meal is gourmet, actually most are not. My goal is to get dinner on the table every night and still keep my sanity. Family dinner is a tradition in my home and the highlight of my day as we all unwind together. Whether I worked for hours or a hungry teenager made food for everyone in 10 minutes, dinner time is sacred.</p>
			<p>Enjoy looking around. I hope you find some new family treasures to build your own family traditions.</p>
			<Feature state={state} />
		</div>
	);
}